// import Swiper JS
import Swiper from 'swiper';

var homeSwiper = new Swiper('.discover-swiper-container', {
    effect: 'fade',
    slidesPerView: 1,
    autoplay: {
        delay: 2500,
        disableOnInteraction: false,
    }
  });