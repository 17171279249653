/**
 * App
*/

import $ from "jquery";
import 'Components/accordion.js';
import 'Components/slider.js';
import AOS from "../../node_modules/aos/dist/aos";
import navbar from "Components/navbar";


document.addEventListener('DOMContentLoaded', function() {
    window.$ = $;

    AOS.init({
        offset: -210,
    });

    navbar();

    $('a[href^="#"]').on('click',function(e) {
        e.preventDefault();
        var target = this.hash;
        var header = $('.header').height();
        var $target = $(target);
        $('html, body').stop().animate({
            'scrollTop': ($target.offset().top - header)
        }, 900, 'swing', function () {
            window.location.hash = target;
        });
    });
});